import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				GearGurus
			</title>
			<meta name={"description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:title"} content={"GearGurus"} />
			<meta property={"og:description"} content={"Експертний догляд за вашою їздою"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-4.jpg?v=2024-03-14T13:00:58.961Z"} />
		</Helmet>
		<Components.Header2 />
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box margin="-16px -16px -16px -16px" padding="16px 16px 16px 16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" lg-width="100%">
					<Box
						background="url(https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/1-4.jpg?v=2024-03-14T13:00:58.862Z) bottom/cover"
						padding="0px 0px 672px 0px"
						margin="0px -112px 0px 0px"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 400px 0px"
					/>
				</Box>
				<Box width="50%" display="flex" padding="16px 16px 0px 16px" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						mix-blend-mode="lighten"
						background="--color-primary"
						margin="36px 0px -20px -112px"
						display="flex"
						flex-direction="column"
						color="--light"
						lg-margin="0px 0px 0px 0px"
						lg-width="100%"
						sm-padding="64px 32px 64px 32px"
					>
						<Text as="h1" margin="0 0 16px 0" font="--headline1" lg-font="--headline2">
							Наші послуги в GearGurus
						</Text>
						<Text as="p" margin="16px 0" font="--lead" max-width="400px">
							GearGurus займається не лише плановим ремонтом; Ми вдосконалюємо кожен аспект обслуговування автомобіля. Наш пакет послуг призначений для вимогливих водіїв, які шукають бездоганну продуктивність, надійне технічне обслуговування та профілактичний ремонт автомобіля.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 0 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Технічне обслуговування та налаштування
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13:00:58.880Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-1.jpg?v=2024-03-14T13%3A00%3A58.880Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Справність двигуна
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Регулярна діагностика та налаштування для оптимальної роботи.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13:00:58.919Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-2.jpg?v=2024-03-14T13%3A00%3A58.919Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Заміна масла
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Першокласні масла для серця вашого автомобіля, що забезпечують безперебійну роботу.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13:00:58.872Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/2-3.jpg?v=2024-03-14T13%3A00%3A58.872Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Гальма та підвіска
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Ми гарантуємо вашу безпеку за допомогою точного ремонту гальм і регулювання підвіски.
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-primary"
				font="--lead"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Контакти
			</Button>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Product-3">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="--headline2"
				text-align="center"
				lg-width="70%"
				md-width="100%"
			>
				Ремонтні послуги
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 50px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13:00:58.869Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/4-4.jpg?v=2024-03-14T13%3A00%3A58.869Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Розширена діагностика
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Новітня технологія для точного виявлення проблем.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13:00:58.863Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-3.jpg?v=2024-03-14T13%3A00%3A58.863Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Механічний ремонт
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Від незначних коригувань до капітального ремонту, ми впораємося з усім цим з досвідом.
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13:00:58.858Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						srcSet="https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65f2c939fdcae00021e01521/images/3-1.jpg?v=2024-03-14T13%3A00%3A58.858Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" font="--headline3" text-align="center">
						Електричні системи
					</Text>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--lead" text-align="center">
						Експертне усунення несправностей і ремонт складної електромережі вашого автомобіля.
					</Text>
				</Box>
			</Box>
			<Button
				padding="14px 34px 14px 34px"
				background="--color-primary"
				font="--lead"
				href="/contacts"
				type="link"
				text-decoration-line="initial"
			>
				Контакти
			</Button>
		</Section>
		<Components.Footer12 />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65d5d38ffdcae00021def28a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});